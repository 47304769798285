import React from "react"
import { graphql, Link } from "gatsby"
//import Img from "gatsby-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { groupBy, getDateYear } from "../utils"
import he from "he"

const BlogPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges.filter(
    p => p.node.frontmatter.date !== null
  )

  const postsList = posts =>
    posts.map(post => (
      <li key={post.node.id} className="flex mb-3">
        <div className="w-1/6 text-sm pt-0.5 ordinal">
          {post.node.frontmatter.date.substring(0, 6)}
        </div>
        <div className="w-5/6">
          <Link
            to={decodeURI(`/${post.node.fields.slug.replace(/\/+$/, "")}/`)}
          >
            {he.decode(post.node.frontmatter.title)}
          </Link>
        </div>
      </li>
    ))

  const postsListContainer = groupBy(posts, getDateYear)
    .map(({ year, posts }, i) => (
      <div key={i}>
        <h4 className="text-xl font-bold mb-5">{year}</h4>
        <ul>{postsList(posts)}</ul>
        <div className="divider"></div>
      </div>
    ))
    .reverse()

  return (
    <Layout>
      <Seo title="Blog" />
      <Link to="/" title="Back to Blog" className="inline-block mb-7">
        &larr; Home
      </Link>
      <br />
      <section className="postlist">{postsListContainer}</section>
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMM DD YYYY")
            title
          }
        }
      }
    }
  }
`
